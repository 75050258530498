import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Apiservices from '../Utils/Apiservices';
import { isAuthenticated } from '../Utils/SessionData';
import InfraRadNavBar from './InfraRadNavBar/InfraRadNavBar';

const AdditionalInfoPage = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [organization, setOrganization] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fullName.trim()) {
      setSnackbar({ open: true, message: 'Full Name is required', severity: 'error' });
      return;
    }
    setIsLoading(true);
    try {
      const response = await Apiservices.post('/api/user/additional-info', {
        fullName,
        organization,
        referralCode
      });

      if (response.data.success) {
        setSnackbar({ open: true, message: 'Information saved successfully', severity: 'success' });
        setTimeout(() => navigate('/client/projects'), 2000);
      } else {
        throw new Error(response.data.message || 'Failed to save information');
      }
    } catch (error) {
      console.error('Error saving additional info:', error);
      setSnackbar({ open: true, message: error.message || 'Failed to save information. Please try again.', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <InfraRadNavBar />
      <Container component="main" maxWidth="xs">
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            Additional Information
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="fullName"
              label="Full Name"
              name="fullName"
              autoFocus
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              sx={{ input: { color: 'white' }, bgcolor: 'darkgrey' }} // Change text color to white and background to dark grey
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="organization"
              label="Organization"
              name="organization"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
              sx={{ input: { color: 'white' }, bgcolor: 'darkgrey' }} // Change text color to white and background to dark grey
            />
            <TextField
              margin="normal"
              fullWidth
              id="referralCode"
              label="Referral Code (Optional)"
              name="referralCode"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              sx={{ input: { color: 'white' }, bgcolor: 'darkgrey' }} // Change text color to white and background to dark grey
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </Box>
        </Box>
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          <MuiAlert elevation={6} variant="filled" severity={snackbar.severity}>
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    </>
  );
};

export default AdditionalInfoPage;