import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { Button, CircularProgress, Paper, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import Apiservices from "../../Utils/Apiservices";
import { isAuthenticated } from "../../Utils/SessionData";
import { motion } from "framer-motion";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-json';

import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TerraformCode = ({ project }) => {
  const [terraformData, setTerraformData] = useState("");
  const [loading, setLoading] = useState(false);
  const [cloudProvider, setCloudProvider] = useState('');
  const [region, setRegion] = useState('');

  const cloudProviders = ['AWS', 'Azure', 'Google Cloud'];
  const regions = {
    'AWS': ['us-east-1', 'us-west-2', 'eu-west-1'],
    'Azure': ['eastus', 'westeurope', 'southeastasia'],
    'Google Cloud': ['us-central1', 'europe-west1', 'asia-east1']
  };

  const handleCloudProviderChange = (event) => {
    setCloudProvider(event.target.value);
    setRegion('');
    setTerraformData('');
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  useEffect(() => {
    if (cloudProvider && region) {
      setLoading(true);
      Apiservices.get(`/api/generate/terraform/${project.id}`, {
        params: {
          cloud_provider: cloudProvider,
          region: region,
        },
      })
        .then((response) => {
          setTerraformData(response.data.data.terraform_code || "");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setTerraformData("");
          setLoading(false);
        });
    }
  }, [project, cloudProvider, region]);

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([terraformData], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${project.name}.tf`; 
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Paper elevation={12} style={{ padding: "20px", marginTop: "20px", backgroundColor: "#1E1E1E" }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="cloud-provider-label" sx={{ color: 'white', backgroundColor: '#1E1E1E', padding: '0 5px' }}>Cloud Provider</InputLabel>
          <Select
            labelId="cloud-provider-label"
            value={cloudProvider}
            onChange={handleCloudProviderChange}
            label="Cloud Provider"
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
              '& .MuiSvgIcon-root': { color: 'white' }
            }}
          >
            {cloudProviders.map((provider) => (
              <MenuItem key={provider} value={provider}>{provider}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="region-label" sx={{ color: 'white', backgroundColor: '#1E1E1E', padding: '0 5px' }}>Region</InputLabel>
          <Select
            labelId="region-label"
            value={region}
            onChange={handleRegionChange}
            label="Region"
            disabled={!cloudProvider}
            sx={{
              color: 'white',
              '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
              '& .MuiSvgIcon-root': { color: 'white' }
            }}
          >
            {cloudProvider && regions[cloudProvider].map((region) => (
              <MenuItem key={region} value={region}>{region}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h6" gutterBottom style={{ color: "#FFFFFF" }}>
            Terraform Code
          </Typography>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <SyntaxHighlighter
              language="hcl"
              style={vs2015}
              customStyle={{
                padding: "20px",
                borderRadius: "4px",
                maxHeight: "60vh",
                overflowY: "auto",
              }}
            >
              {terraformData}
            </SyntaxHighlighter>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownload}
              style={{ marginTop: "20px" }}
              startIcon={<GetAppIcon />}
            >
              Download .tf File
            </Button>
          </motion.div>
        </motion.div>
      )}
    </Paper>
  );
};

export default TerraformCode;
