import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#68CC58',
  '&:hover': {
    backgroundColor: '#5ab54b',
  },
}));

const CustomButton = React.forwardRef((props, ref) => {
  return <StyledButton {...props} ref={ref} />;
});

export default CustomButton;