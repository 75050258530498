import axios from "axios";
import { clearUserData } from "./SessionData";

const baseURL = process.env.REACT_APP_BASE_URL;

class ApiService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      withCredentials: true,
    });
    console.log("Axios instance created with baseURL:", baseURL);

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          // Handle unauthorized error (e.g., redirect to login)
          this.logout();
        }
        return Promise.reject(error);
      }
    );
  }

  logout() {
    clearUserData();
    this.axiosInstance.post("/api/auth/logout");
    window.location.href = '/login';
  }

  get = async (url = "", headers = {}) => {
    try {
      console.log("Request headers:", headers);
      const response = await this.axiosInstance.get(url, { headers });
      console.log("Response:", response);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error("Error response:", error.response);
      throw error;
    }
  };

  post = async (url, payload = {}, headers = {}) => {
    try {
      const response = await this.axiosInstance.post(url, payload, { headers });
      return response.data;
    } catch (error) {
      console.log("Post Request Error:", error);
      throw error;
    }
  };

  put = async (url, payload = {}, headers = {}) => {
    try {
      const response = await this.axiosInstance.put(url, payload, { headers });
      return response.data;
    } catch (error) {
      console.log("Post Request Error:", error);
      throw error;
    }
  };

  delete = async (url, headers = {}) => {
    try {
      const response = await this.axiosInstance.delete(url, { headers });
      return response.data;
    } catch (error) {
      console.log("Delete Request Error:", error);
      throw error;
    }
  };

  // ... (other methods)
}

export default new ApiService();
