import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";
import DiagramCodePrompt from "./DiagramCodePrompt";
import Resources from "./Resources";
import TerraformCode from "./TerraformCode";
import { checkIsAuthenticated } from "../../Utils/SessionData";
import Apiservices from "../../Utils/Apiservices";
import ShareIcon from "@mui/icons-material/Share";
import Documentation from "./Documentation.js";
import ProjectWizard from "./ProjectWizard";
import SyncSettings from "./SyncSettings";

const ProjectDetails = ({ project }) => {
  const isUserAuthenticated = checkIsAuthenticated();
  const [activeTab, setActiveTab] = useState(0);
  const [updatedProject, setUpdatedProject] = useState(project);
  const [projectLoading, setProjectLoading] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (project && project.id) {
      fetchProjectDetails();
    }
  }, [project]);

  const fetchProjectDetails = async () => {
    if (!project || !project.id) return;
    setProjectLoading(true);
    try {
      const response = await Apiservices.get(`/api/projects/${project.id}`);
      setUpdatedProject(response);
    } catch (error) {
      console.error("Error fetching project details:", error);
    } finally {
      setProjectLoading(false);
    }
  };

  const handleProjectUpdate = (newData) => {
    setUpdatedProject(prevProject => ({
      ...prevProject,
      ...newData
    }));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!project) {
    return <div>No project details available.</div>;
  }

  const isNewProject = !updatedProject.infra_diagram_url;
  const projectChoice = localStorage.getItem(`project_${updatedProject.id}_choice`);

  if (isNewProject && (!projectChoice || projectChoice === 'existing')) {
    return <ProjectWizard project={updatedProject} onProjectUpdate={handleProjectUpdate} />;
  }

  return (
    <Box sx={{ width: "100%", mb: 4 }}> 
      <Paper style={{ padding: "16px", marginBottom: "16px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">Project Details</Typography>
            <Typography>ID: {updatedProject.id}</Typography>
            <Typography>Name: {updatedProject.name}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<ShareIcon />}
              onClick={() => window.open(`${baseURL}/api/share/${updatedProject?.share_code}`, "_blank")}
            >
              Share
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        aria-label="project details tabs"
        sx={{
          '& .MuiTab-root': {
            color: 'white',  // This sets all tab text to white
          },
          '& .Mui-selected': {
            color: '#68CC58',  // This keeps the active tab text green
          },
        }}
      >
        <Tab label="Diagram" />
        <Tab label="Resources" />
        <Tab label="Terraform" />
        <Tab label="Documentation" />
        <Tab label="Sync Settings" />
      </Tabs>

      <Box sx={{ mt: 2 }}>
        {projectLoading ? (
          <CircularProgress />
        ) : (
          <>
            {activeTab === 0 && (
              <DiagramCodePrompt
                project={updatedProject}
                funcCode={updatedProject.generated_code}
                fname={updatedProject.fname}
                infra_diagram_url={updatedProject.infra_diagram_url}
                id={updatedProject.id}
                onProjectUpdate={handleProjectUpdate}
              />
            )}
            {activeTab === 1 && (
              <Resources
                project={updatedProject}
                resources={updatedProject.resources}
              />
            )}
            {activeTab === 2 && (
              <TerraformCode project={updatedProject} />
            )}
            {activeTab === 3 && (
              <Documentation project={updatedProject} />
            )}
            {activeTab === 4 && (
              <SyncSettings project={updatedProject} />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProjectDetails;
