import Apiservices from "../Apiservices";
import { isAuthenticated } from "../SessionData";

class CreditsFetch {
  fetchAvailableCredits = async () => {
    try {
      const data = await Apiservices.get(`/api/user/credits`);
      console.log("Credits data:", data);
      
      // Store credits in localStorage
      if (data && data.credit_balance !== undefined) {
        localStorage.setItem("MyCredits", data.credit_balance);
      }
      
      return data;
    } catch (error) {
      console.error("Error fetching credits:", error);
      return error;
    }
  };

  // New method to update credits in localStorage
  updateCreditsInStorage = (credits) => {
    localStorage.setItem("MyCredits", credits);
  };
}

export default new CreditsFetch();
