import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from 'react-router-dom';
import LoginPage from './Components/LoginPage/LoginPage';
import SignUpPage from './Components/SignUpPage/SignUpPage';
import ClientRoutes from './Utils/Routes/ClientRoutes';
import OAuthHandler from './Components/OAuthHandler';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './Components/ContactUs/ContactUs';
import TermsAndConditions from './Components/TermsAndConditions/TermsAndConditions';
import RefundPolicy from './Components/RefundPolicy/RefundPolicy';
import Footer from './Components/Footer';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import InfraRadNavBar from './Components/InfraRadNavBar/InfraRadNavBar';
import { useLocation } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import AdditionalInfoPage from './Components/AdditionalInfoPage';
import Settings from './Components/Settings/Settings';
import GitHubCallback from './Components/GitHubCallback/GitHubCallback';
import { checkIsAuthenticated } from './Utils/SessionData';
import Projects from './Components/Projects/Projects';
import Tools from './Components/Tools/Tools';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const secretKey = process.env.REACT_APP_USER_ENC_DEC_KEY;

    useEffect(() => {
        const checkAuth = () => {
            const auth = checkIsAuthenticated();
            setIsAuthenticated(auth);
            setIsLoading(false);
        };
        checkAuth();
    }, [location]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Routes>
                    <Route path="/" element={
                        isAuthenticated ? <Navigate to="/client/projects" replace /> : <LoginPage />
                    } />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/oauth-callback" element={<OAuthHandler secretKey={secretKey} />} />
                    <Route path="/create-new-account/:referral_code?" element={<SignUpPage />} />
                    <Route path="/additional-info" element={<AdditionalInfoPage />} />
                    <Route path="/client/*" element={
                        isAuthenticated ? (
                            <>
                                <InfraRadNavBar />
                                <div style={{ flex: 1, padding: '20px' }}>
                                    <ClientRoutes />
                                </div>
                            </>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    } />
                    <Route path="/policy/privacy" element={<PrivacyPolicy />} />
                    <Route path="/policy/contact-us" element={<ContactUs />} />
                    <Route path="/policy/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/policy/refund-policy" element={<RefundPolicy />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/auth/callback/github" element={<GitHubCallback />} />
                    <Route path="/projects" component={Projects} />
                    <Route path="/tools" component={Tools} />
                </Routes>
                <Footer />
            </div>
            <Analytics />
        </ThemeProvider>
    );
}

export default App;
