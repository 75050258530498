import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Grid, Paper, Typography, Select, MenuItem, Button } from '@mui/material';
import '@mdxeditor/editor/style.css';
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, thematicBreakPlugin, linkPlugin, markdownShortcutPlugin, imagePlugin } from '@mdxeditor/editor';
import Apiservices from "../../Utils/Apiservices";
import { isAuthenticated } from "../../Utils/SessionData";
// Import debounce from lodash or create your own
import { debounce } from 'lodash';

const darkTheme = {
  darkTheme: {
    '--accentBase': 'var(--tomato-1)',
    '--accentBgSubtle': 'var(--tomato-2)',
    '--accentBg': 'var(--tomato-3)',
    '--accentBgHover': 'var(--tomato-4)',
    '--accentBgActive': 'var(--tomato-5)',
    '--accentLine': 'var(--tomato-6)',
    '--accentBorder': 'var(--tomato-7)',
    '--accentBorderHover': 'var(--tomato-8)',
    '--accentSolid': 'var(--tomato-9)',
    '--accentSolidHover': 'var(--tomato-10)',
    '--accentText': 'var(--tomato-11)',
    '--accentTextContrast': 'var(--tomato-12)',
    '--baseBase': 'var(--mauve-1)',
    '--baseBgSubtle': 'var(--mauve-2)',
    '--baseBg': 'var(--mauve-3)',
    '--baseBgHover': 'var(--mauve-4)',
    '--baseBgActive': 'var(--mauve-5)',
    '--baseLine': 'var(--mauve-6)',
    '--baseBorder': 'var(--mauve-7)',
    '--baseBorderHover': 'var(--mauve-8)',
    '--baseSolid': 'var(--mauve-9)',
    '--baseSolidHover': 'var(--mauve-10)',
    '--baseText': 'var(--mauve-11)',
    '--baseTextContrast': 'var(--mauve-12)',
    '--basePageBg': 'black',
    '--codeBackground': '#2E2E2E',
    '--codeBorder': '#4E4E4E',
    '--codeText': 'white',
  }
};

const Documentation = ({ project }) => {
  const [markdown, setMarkdown] = useState(project['documentation'] || '# Your documentation here');
  const [documentType, setDocumentType] = useState('');
  const [aiPrompt, setAiPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const editorRef = useRef(null);

  useEffect(() => {
    if (project['documentation'] !== markdown) {
      setMarkdown(project['documentation'] || '# Your documentation here');
      if (editorRef.current) {
        editorRef.current.scrollTop = 0;
      }
    }
  }, [project, markdown]);

  const saveDocumentation = useCallback(async (content) => {
    try {
      await Apiservices.put(
        `/api/projects/${project.id}/documentation`,
        { documentation: content }
      );
    } catch (err) {
      console.error('Error saving documentation:', err);
      setError('Failed to save documentation. Please try again.');
    }
  }, [project.id]);

  const debouncedSave = useCallback(
    debounce((content) => {
      saveDocumentation(content).catch(console.error);
    }, 3000),
    [saveDocumentation]
  );

  const handleMarkdownChange = useCallback((content) => {
    setMarkdown(content);
    debouncedSave(content);
  }, [debouncedSave]);

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const handleAiPromptChange = (event) => {
    setAiPrompt(event.target.value);
  };

  const handleGenerateDocument = async () => {
    setIsLoading(true);
    setError('');

    try {
      console.log('Sending request to generate document...');
      const response = await Apiservices.post(
        `/api/generate/documentation/${project.id}`,
        { documentType, aiPrompt }
      );

      if (response.error) {
        throw new Error(response.error.message || 'Failed to generate document');
      }

      if (response.data && response.data.documentation) {
        setMarkdown(response.data.documentation);
      } else {
        throw new Error('No documentation received from the server');
      }
    } catch (err) {
      setError('Failed to generate document. Please try again.');
      console.error('Error generating document:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = useCallback(async (file) => {
    // This is a placeholder function. You should implement your own image upload logic here.
    console.log('Image upload requested:', file.name);
    return Promise.resolve(`https://example.com/images/${file.name}`);
  }, []);

  const CustomImage = ({ alt, src, title }) => {
    return (
      <img
        src={src}
        alt={alt}
        title={title}
        style={{
          maxWidth: '100%',
          height: 'auto',
          display: 'block',
          margin: '10px 0',
        }}
      />
    );
  };

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', backgroundColor: '#1E1E1E', color: 'white' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={8} sx={{ height: '100%', overflow: 'hidden' }}>
          <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden', backgroundColor: '#2E2E2E' }}>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }} ref={editorRef}>
              <MDXEditor
                className="dark-theme"
                markdown={markdown}
                onChange={handleMarkdownChange}
                plugins={[
                  headingsPlugin(),
                  listsPlugin(),
                  quotePlugin(),
                  thematicBreakPlugin(),
                  linkPlugin(),
                  markdownShortcutPlugin(),
                  imagePlugin({
                    imageUploadHandler: handleImageUpload,
                    imageAutocompleteSuggestions: [
                      'https://picsum.photos/200/300',
                      'https://picsum.photos/300/400',
                      'https://picsum.photos/400/500',
                    ],
                    imageComponent: CustomImage,
                  })
                ]}
                contentEditableClassName="prose max-w-full"
                style={{ height: '100%', minHeight: '100%' }}
                theme={darkTheme}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4} sx={{ height: '100%', overflow: 'auto' }}>
          <Paper sx={{ height: 'auto', padding: 2, backgroundColor: '#2E2E2E' }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
              Generate Documentation Outline
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: 'white' }}>
              Project Name: {project.name}
            </Typography>
            <Select
              fullWidth
              value={documentType}
              onChange={handleDocumentTypeChange}
              displayEmpty
              sx={{ 
                marginBottom: 2, 
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                '& .MuiSvgIcon-root': { color: 'white' }
              }}
            >
              <MenuItem value="" disabled>
                Select document type
              </MenuItem>
              <MenuItem value="documentation">Documentation</MenuItem>
              <MenuItem value="other">Other- (Mention in the prompt)</MenuItem>
            </Select>
            <Typography variant="subtitle1" gutterBottom sx={{ color: 'white' }}>
              AI Prompt
            </Typography>
            <textarea
              value={aiPrompt}
              onChange={handleAiPromptChange}
              style={{ 
                width: '100%', 
                minHeight: '200px', 
                marginBottom: '16px',
                backgroundColor: '#3E3E3E',
                color: 'white',
                border: '1px solid white',
                padding: '8px'
              }}
            />
            {error && (
              <Typography color="error" sx={{ marginBottom: 2, color: 'white' }}>
                {error}
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateDocument}
              disabled={!documentType || !aiPrompt || isLoading}
            >
              {isLoading ? 'Generating...' : 'Generate Document'}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Documentation;