import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Grid,
  Divider,
  IconButton,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getDecryptedUserData } from "../../Utils/SessionData";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomButton from '../CustomButton';
import { Analytics } from '@vercel/analytics/react';

const UserProfile = () => {
  const userData = getDecryptedUserData();
  const { email, full_name, organization_name, phone, referral_code, user_id } = userData || {};

  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Function to copy referral link to clipboard
  const handleCopyReferralLink = () => {
    const referralLink = `${process.env.REACT_APP_SERVER}/create-new-account/${referral_code}`;
    navigator.clipboard.writeText(referralLink).then(
      () => {
        setSnackbarMessage("Referral link copied to clipboard!");
        setSnackbarOpen(true);
      },
      (err) => {
        setSnackbarMessage("Failed to copy referral link.");
        setSnackbarOpen(true);
      }
    );
  };

  // Function to close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };


  const fullName = full_name;
const firstInitial = fullName[0]; // 'J'
const secondInitial = fullName[fullName.indexOf(' ') + 1]; // 'D'

console.log(firstInitial, secondInitial); // Output: J D


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#121212",
        padding: "20px",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          maxWidth: "600px",
          width: "100%",
          backgroundColor: "#2c2c2c", // Lightened background
          borderRadius: "10px",
          color: "#ffffff", // White text for better contrast
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "20px", color: "#ffffff" }}>
            User Profile
          </Typography>
          {/* <IconButton color="primary" aria-label="edit profile">
            <EditIcon />
          </IconButton> */}
        </Box>
        <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
          <Avatar
            sx={{ 
              width: 100, 
              height: 100, 
              margin: "0 auto", 
              fontSize: "40px",
              backgroundColor: "#1976d2", // Blue background for Avatar
              color: "#ffffff", // White text for Avatar
            }}
          >
            {firstInitial + secondInitial}
            {/* {full_name[0]} */}
          </Avatar>
          <Typography variant="h6" sx={{ marginTop: "10px", color: "#ffffff" }}>
            {full_name}
          </Typography>
        </Box>
        <Divider sx={{ marginBottom: "20px", backgroundColor: "#555555" }} /> 
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#ffffff" }}>
              Email:
            </Typography>
            <Typography variant="body2" sx={{ color: "#bbbbbb" }}> 
              {email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#ffffff" }}>
              Phone:
            </Typography>
            <Typography variant="body2" sx={{ color: "#bbbbbb" }}>
              {phone}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#ffffff" }}>
              Organization Name:
            </Typography>
            <Typography variant="body2" sx={{ color: "#bbbbbb" }}>
              {organization_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#ffffff" }}>
            Referral Code:
            </Typography>
            <Typography variant="body2" sx={{ color: "#bbbbbb" }}>
              {referral_code}
            </Typography>
          </Grid>
        </Grid>
        {/* Add the referral link copy button */}
        {/* {referral_code && ( */}
          <Box sx={{ textAlign: "center", marginTop: "20px" }}>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleCopyReferralLink}
            >
             <ContentCopyIcon/> {" "} Copy Referral Link
            </CustomButton>
          </Box>
        {/* )} */}
      </Paper>
      {/* Snackbar for showing copy success message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Analytics />
    </Box>
  );
};

export default UserProfile;
