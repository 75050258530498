import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Paper, Card, CardContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import RepositorySelection from './RepositorySelection';
import { GitHub } from '@mui/icons-material';

const ProjectWizard = ({ project, onProjectUpdate }) => {
  const [choice, setChoice] = useState(null);

  const handleChoice = useCallback((selectedChoice) => {
    setChoice(selectedChoice);
    localStorage.setItem(`project_${project.id}_choice`, selectedChoice);
    onProjectUpdate({ choice: selectedChoice });
  }, [project.id, onProjectUpdate]);

  useEffect(() => {
    const savedChoice = localStorage.getItem(`project_${project.id}_choice`);
    if (savedChoice && savedChoice !== choice) {
      handleChoice(savedChoice);
    }
  }, [project.id, handleChoice, choice]);

  if (choice === 'new') {
    return null; // This will allow ProjectDetails to render its normal view for new projects
  }

  if (choice === 'existing') {
    return <RepositorySelection project={project} onProjectUpdate={onProjectUpdate} />;
  }

  return (
    <Box sx={{ width: '100%', mb: 4 }}>
      <Paper style={{ padding: '24px', marginBottom: '16px' }}>
        <Typography variant="h5" gutterBottom align="center">
          Welcome to Your New Project
        </Typography>
        <Typography paragraph align="center" sx={{ mb: 4 }}>
          Would you like to start a new project or connect to an existing project on GitHub?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mt: 4 }}>
          <Card 
            sx={{ 
              width: 300, 
              height: 350, 
              cursor: 'pointer', 
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 3,
              },
            }} 
            onClick={() => handleChoice('new')}
          >
            <CardContent sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              p: 3, 
              pb: 4 // Add bottom padding
            }}>
              <Box sx={{ textAlign: 'center' }}>
                <AddIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                <Typography variant="h6" component="div" align="center">
                  Start New Project
                </Typography>
              </Box>
              <Typography variant="body2" align="center">Ideal for when you're starting a new project from scratch. You can design the architecture, get a cost estimate, build a design document, and more.</Typography>
            </CardContent>
          </Card>
          <Card 
            sx={{ 
              width: 300, 
              height: 350, 
              cursor: 'pointer', 
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 3,
              },
            }} 
            onClick={() => handleChoice('existing')}
          >
            <CardContent sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              p: 3, 
              pb: 4 // Add bottom padding
            }}>
              <Box sx={{ textAlign: 'center' }}>
                <GitHub sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                <Typography variant="h6" component="div" align="center">
                  Sync an existing Project
                </Typography>
              </Box>
              <Typography variant="body2" align="center">
                Ideal for when you are working with an existing project on GitHub. You can sync the project, generate inline documentation, build a swagger api docs, and more.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Paper>
    </Box>
  );
};

export default ProjectWizard;
