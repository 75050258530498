import React from "react";
import { Routes, Route } from "react-router-dom";
import MyProjects from "../../Components/MyProjects/MyProjects";
import UserProfile from "../../Components/UserProfile/UserProfile";
import Settings from '../../Components/Settings/Settings';

const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="projects" element={<MyProjects />} />
      <Route path="profile" element={<UserProfile />} />
      <Route path="settings" element={<Settings />} />
    </Routes>
  );
};

export default ClientRoutes;
