import React from 'react';
import { Typography, Card, CardContent, IconButton, Stack, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Prompts = ({ prompt_history }) => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <Box sx={{ height: '100%', overflowY: 'scroll' }}>
      {prompt_history && prompt_history.length > 0 ? (
        <Stack spacing={2}>
          {prompt_history.map((item, index) => (
            <Card key={index} variant="outlined">
              <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '8px' }}>
                <Typography variant="body2" style={{ flexGrow: 1 }}>
                  {item.content}
                </Typography>
                <IconButton 
                  onClick={() => handleCopy(item.content)} 
                  size="small" 
                  style={{ marginLeft: '8px' }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </CardContent>
            </Card>
          ))}
        </Stack>
      ) : (
        <Typography variant="h6" color="textSecondary">
          No prompt history found.
        </Typography>
      )}
    </Box>
  );
};

export default Prompts;