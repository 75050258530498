import React from 'react';
import { Container, Typography, Box, Paper, List, ListItem } from '@mui/material';

const RefundPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Cancellation & Refund Policy
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
          <Typography variant="body2" paragraph>
            Last updated on 13-09-2024 18:12:01
          </Typography>
          <Typography variant="body1" paragraph>
            Infrajam believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
          </Typography>
          <List>
            <ListItem sx={{ display: 'list-item', listStyleType: 'disc', pl: 0, ml: 4 }}>
              <Typography variant="body2">
                Cancellations will be considered only if the request is made immediately after placing the order and no credits have been utilized.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', listStyleType: 'disc', pl: 0, ml: 4 }}>
              <Typography variant="body2">
                Under normal circumstances Cancellation would be possible for the next payment cycle. This is applicable for the monthly subscription plan.
                For annual subscription plan, the cancellation would be possible for the next payment cycle i.e after the current billing period.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', listStyleType: 'disc', pl: 0, ml: 4 }}>
              <Typography variant="body2">
                In exceptional cases, we can refund the value of the unutilized credits on a prorated basis, subject to the sole discretion of the company.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="body1" paragraph sx={{ mt: 2 }}>
            Due to the nature of the platform, credits which have been utilized cannot be refunded back, since the cost of using generative models has already been incurred.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default RefundPolicy;