import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_USER_ENC_DEC_KEY;

export const checkIsAuthenticated = () => {
  // Check if user data exists in localStorage
  return !!localStorage.getItem("UserProfile");
};

// We'll no longer need these functions as tokens will be stored in HttpOnly cookies
// export const getDecryptedAccessToken = () => { ... }
// export const getDecryptedRefreshToken = () => { ... }

export const getDecryptedUserData = () => {
  const encryptedData = localStorage.getItem("UserProfile");
  if (encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  }
  return null;
};

export const setEncryptedUserData = (userData) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userData), secretKey).toString();
  localStorage.setItem("UserProfile", encryptedData);
};

export const clearUserData = () => {
  localStorage.removeItem("UserProfile");
};