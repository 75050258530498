import React from 'react';
import { Box, Typography, IconButton, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { YouTube } from '@mui/icons-material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#121212',
        color: 'white',
        py: 3,
        px: 2,
        mt: 'auto',
        borderTop: '1px solid #2a2a2a',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Box>
            <Typography variant="body2" color="white">
              © {new Date().getFullYear()} Infrajam. All rights reserved.
            </Typography>
            <Typography variant="caption" color="white">
              Infrajam is a trademark of Infrajam Technologies Inc.
            </Typography>
          </Box>
          <Box>
            <IconButton color="inherit" aria-label="Youtube" component="a" href="https://www.youtube.com/@infrajam" target="_blank" rel="noopener noreferrer">
              <YouTube />
            </IconButton>
            <IconButton color="inherit" aria-label="Twitter" component="a" href="https://x.com/infrajam" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </IconButton>
            <IconButton color="inherit" aria-label="LinkedIn" component="a" href="https://linkedin.com/company/infrajam" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon />
            </IconButton>
            <IconButton color="inherit" aria-label="Instagram" component="a" href="https://instagram.com/infrajam" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <Link href="/policy/privacy" color="inherit" underline="hover">
            <Typography variant="caption">Privacy Policy</Typography>
          </Link>
          <Link href="/policy/terms-and-conditions" color="inherit" underline="hover">
            <Typography variant="caption">Terms of Service</Typography>
          </Link>
          <Link href="/policy/contact-us" color="inherit" underline="hover">
            <Typography variant="caption">Contact Us</Typography>
          </Link>
          <Link href="/policy/refund-policy" color="inherit" underline="hover">
            <Typography variant="caption">Refund Policy</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;