import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  IconButton,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../Assests/default.svg";
import CryptoJS from "crypto-js";
import Apiservices from "../../Utils/Apiservices";
import { supabase } from '../../supabaseClient';
import CreditsFetch from "../../Utils/CreditsFetch/CreditsFetch";
import CustomButton from '../CustomButton';
import { Analytics } from '@vercel/analytics/react';
import { setEncryptedUserData } from "../../Utils/SessionData";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [session, setSession] = useState(null);
  const secretKey = process.env.REACT_APP_USER_ENC_DEC_KEY;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackBarMessageOpen, setSnackBarMessageOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarMessageType, setSnackBarMessageType] = useState("success");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session) handleSuccessfulLogin(session);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) handleSuccessfulLogin(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    return () => {
      console.log("LoginPage component is unmounting");
    };
  }, []);

  const handlePostLoginActions = async () => {
    console.log("Starting post-login actions");
    
    try {
      // Add a small delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Check if it's a new user
      const newUserResponse = await Apiservices.get('/api/user/check-new-user');
      console.log("New user check response:", newUserResponse);

      // Safely access the new_user property
      if (!newUserResponse || typeof newUserResponse.new_user === 'undefined') {
        console.error("Unexpected response structure from check-new-user endpoint");
        throw new Error("Unexpected response structure");
      }

      const isNewUser = newUserResponse.new_user;
      console.log("Is new user:", isNewUser);

      if (isNewUser) {
        console.log("Redirecting to additional info page");
        setSnackBarMessage("Please provide additional information to complete your profile.");
        setSnackBarMessageType("info");
        setSnackBarMessageOpen(true);
        setLoading(false);
        // Use a callback to ensure navigation happens after state updates
        setTimeout(() => {
          console.log("Navigating to /additional-info");
          navigate("/additional-info", { replace: true });
        }, 100);
      } else {
        const credits = await CreditsFetch.fetchAvailableCredits();
        console.log("Credits fetched successfully:", credits);
        
        setSnackBarMessage("Login Successful!");
        setSnackBarMessageType("success");
        setSnackBarMessageOpen(true);
        
        setLoading(false);
        // Use a callback to ensure navigation happens after state updates
        setTimeout(() => {
          console.log("Navigating to /client/projects");
          navigate("/client/projects", { replace: true, state: { from: 'login' } });
        }, 100);
      }
    } catch (error) {
      console.error("Error in post-login actions:", error);
      setSnackBarMessageType("warning");
      if (error.message === "Unexpected response structure") {
        setSnackBarMessage("Login successful, but there was an issue with the server response. Please try again or contact support.");
      } else {
        setSnackBarMessage("Login successful, but there was an issue fetching user data.");
      }
      setSnackBarMessageOpen(true);
      
      setLoading(false);
      // Use a callback to ensure navigation happens after state updates
      setTimeout(() => {
        console.log("Navigating to /client/projects due to error");
        navigate("/client/projects", { replace: true, state: { from: 'login' } });
      }, 100);
    }
  };

  const handleSuccessfulLogin = async (userData) => {
    console.log("Handling successful login.");

    // Store only non-sensitive user data in localStorage
    setEncryptedUserData({
      email: userData.email,
      full_name: userData.full_name,
      id: userData.id,
      organization_id: userData.organization_id,
      organization_name: userData.organization_name,
      phone: userData.phone,
      referral_code: userData.referral_code,
      role: userData.role
    });

    console.log("User profile data stored in localStorage");

    // Tokens are already set in HttpOnly cookies by the backend
    await handlePostLoginActions();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      email: email,
      password: password,
    };

    try {
      const responseData = await Apiservices.post("/api/auth/login", payload, {
        headers: { "Content-Type": "application/json" },
      });

      console.log("Login response data:", responseData);

      if (responseData && responseData.user) {
        await handleSuccessfulLogin(responseData.user);
      } else {
        throw new Error("User data not found in the response");
      }
    } catch (error) {
      console.error("Login error:", error);
      setSnackBarMessageOpen(true);
      setSnackBarMessageType("error");
      
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;
        if (errorMessage.includes('Email not confirmed')) {
          setSnackBarMessage("Please verify your email (check your mailbox)");
        } else {
          setSnackBarMessage(errorMessage);
        }
      } else if (error.message === "User data not found in the response") {
        setSnackBarMessage("Login successful, but user data is missing. Please contact support.");
      } else {
        setSnackBarMessage("Failed to login. Please check your credentials and try again.");
      }
      
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    if (!secretKey) {
      console.error('Encryption key is not available');
      setSnackBarMessageOpen(true);
      setSnackBarMessageType("error");
      setSnackBarMessage("Unable to initiate login. Please contact support.");
      return;
    }

    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/login`
        }
      });

      if (error) throw error;
    } catch (error) {
      console.error('Error during Google login:', error.message);
      setSnackBarMessageOpen(true);
      setSnackBarMessageType("error");
      setSnackBarMessage("Failed to initiate Google login, please try again.");
    }
  };

  const handleSnackbarClose = () => {
    setSnackBarMessageOpen(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(0deg, #FFFFFF 30%, #131313 99%)",
          }}
      >
        <Container
            component="main"
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
            }}
        >
          <Grid
              container
              spacing={5}
              sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
            >
              <Box sx={{ textAlign: "center", mb: 4 }}>
                <img
                    src={Logo}
                    alt="logo"
                    style={{
                      width: "28rem",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                />
              </Box>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
            >
              <Box
                  sx={{
                    textAlign: "center",
                    bgcolor: "background.default",
                    borderRadius: "8px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    p: 4,
                  }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                      sx={{ bgcolor: "#2689d5", mb: 2, width: 80, height: 80 }}
                  >
                    <AccountCircleIcon sx={{ width: 60, height: 60 }} />
                  </Avatar>
                </Box>
                <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                  User Login
                </Typography>
                <form onSubmit={handleLogin} style={{ width: "100%" }}>
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                  <VisibilityOffIcon />
                              ) : (
                                  <VisibilityIcon />
                              )}
                            </IconButton>
                        ),
                      }}
                  />
                  <CustomButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mt: 3 }}
                      disabled={loading}
                  >
                    {loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        "Sign In"
                    )}
                  </CustomButton>
                  {/* <CustomButton
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleGoogleLogin}
                      sx={{ mt: 2 }}
                  >
                    Sign In with Google
                  </CustomButton> */}
                  <Grid container justifyContent="space-between" sx={{ mt: 1 }}>
                    <Grid item>
                      <Link
                          to="/forgot-password"
                          variant="body2"
                          style={{ color: "#05B6B3" }}
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                          to="/create-new-account"
                          variant="body2"
                          style={{ color: "#05B6B3" }}
                      >
                        Create New Account
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
          <Snackbar
              open={snackBarMessageOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
          >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleSnackbarClose}
                severity={snackBarMessageType}
            >
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
        </Container>
      </Box>
      <Analytics />
    </>
  );
};

export default LoginPage;