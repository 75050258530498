import React, { useState, useEffect } from "react";
import { Tab, Tabs, Box, IconButton } from "@mui/material";
import MyProjectsList from "./MyProjectsList";
import ProjectDetails from "./ProjectDetails.js";
import CloseIcon from "@mui/icons-material/Close";
import Apiservices from "../../Utils/Apiservices";
import { checkIsAuthenticated } from '../../Utils/SessionData';
import { Analytics } from '@vercel/analytics/react';

const MyProjects = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [openedProjects, setOpenedProjects] = useState([]);
  const isUserAuthenticated = checkIsAuthenticated();

  useEffect(() => {
    const savedProjects =
      JSON.parse(localStorage.getItem("openedProjects")) || [];
    setOpenedProjects(savedProjects);
    console.log("Loaded saved projects:", savedProjects); // Debug log
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    console.log("Tab changed to:", newValue); // Debug log
  };

  const handleRowClick = (project) => {
    console.log("Row clicked:", project); // Debug log
    const simplifiedProject = { id: project.id, name: project.name };
  
    const existingProjectIndex = openedProjects.findIndex(
      (p) => p.id === simplifiedProject.id
    );
  
    if (existingProjectIndex === -1) {
      const updatedProjects = [...openedProjects, simplifiedProject];
      setOpenedProjects(updatedProjects);
      localStorage.setItem("openedProjects", JSON.stringify(updatedProjects));
      setTabIndex(updatedProjects.length);
      console.log("New project added:", simplifiedProject); // Debug log
    } else {
      setTabIndex(existingProjectIndex + 1);
      console.log("Existing project selected:", simplifiedProject); // Debug log
    }
  };

  const handleCloseTab = (event, project) => {
    event.stopPropagation();
    const newProjects = openedProjects.filter((p) => p.id !== project.id);
    setOpenedProjects(newProjects);
    localStorage.setItem("openedProjects", JSON.stringify(newProjects));
    if (tabIndex > 0) {
      setTabIndex(0);
    } else {
      setTabIndex(tabIndex - 1);
    }
    console.log("Tab closed:", project); // Debug log
  };

  console.log("Rendering MyProjects. Current tab:", tabIndex); // Debug log

  return (
    <div>
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="project tabs"
          sx={{
            '& .MuiTab-root': {
              color: 'white',  // This sets all tab text to white
            },
            '& .Mui-selected': {
              color: '#68CC58',  // This keeps the active tab text green
            },
          }}
        >
          <Tab label="Project List" />
          {openedProjects.map((project, index) => (
            <Tab
              key={project.id}
              label={
                <span>
                  {project.name}
                  <IconButton
                    size="small"
                    onClick={(event) => handleCloseTab(event, project)}
                    style={{ marginLeft: 8, color: 'white' }}  // Ensure close icon is also white
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </span>
              }
            />
          ))}
        </Tabs>
      </Box>
      {tabIndex === 0 && <MyProjectsList onRowClick={handleRowClick} />}
      {tabIndex > 0 && (
        <ProjectDetails
          project={openedProjects[tabIndex - 1]}
        />
      )}
      <Analytics />
    </div>
  );
};

export default MyProjects;
