import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Apiservices from "../../Utils/Apiservices";

const GitHubCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState('Processing...');

  useEffect(() => {
    const fetchToken = async () => {
      const searchParams = new URLSearchParams(location.search);
      const { state, setup_action: setupAction, installation_id: installationId } = Object.fromEntries(searchParams);

      console.log('Extracted params:', { state, setupAction, installationId });

      try {
        if (installationId && state) {
          console.log('Attempting to fetch token...');
          const response = await Apiservices.post('/api/auth/github/callback', { installationId, setupAction, state });


          if (response) {
            const data = await response.json();
            console.log('Received data:', data);
            setStatus('Installation successful');
          } else {
            console.error('API call failed with status:', response.status);
            setStatus('Installation failed');
          }
        } else {
          console.log('No installationId or state found');
          setStatus('Missing parameters');
        }
      } catch (error) {
        console.error('Error during token fetch:', error);
        setStatus('Error occurred');
      } finally {
        console.log('Navigating to settings page');
        setTimeout(() => navigate('/client/settings'), 2000);
      }
    };

    fetchToken();
  }, [location, navigate]);

  return <div>{status}</div>;
};

export default GitHubCallback;