import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import { isAuthenticated } from '../../Utils/SessionData';
import Apiservices from '../../Utils/Apiservices';
import CreditsFetch from '../../Utils/CreditsFetch/CreditsFetch';
import Prompts from './Prompts';

const DiagramCodePrompt = ({ project, funcCode, fname, infra_diagram_url, id, onProjectUpdate }) => {
  const [code, setCode] = useState(funcCode);
  const [newPrompt, setNewPrompt] = useState('');
  const [rightPaneTab, setRightPaneTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [diagramUrl, setDiagramUrl] = useState(infra_diagram_url);

  useEffect(() => {
    setCode(funcCode);
    setDiagramUrl(infra_diagram_url);
  }, [funcCode, infra_diagram_url]);

  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  const handlePromptChange = (event) => {
    setNewPrompt(event.target.value);
  };

  const handleCodeUpdate = async () => {
    setLoading(true);
    const payload = {
      project_id: id,
      code: code,
      fname: project.fname,
    };

    try {
      const response = await Apiservices.post("/api/generate/update", payload, {
        "Content-Type": "application/json",
      });

      setSnackbar({ open: true, message: "Code updated successfully!", severity: "success" });
      
      // Update the local state with the new code and diagram URL
      setCode(response.data.code);
      setDiagramUrl(`${response.data.infra_diagram_url}?t=${new Date().getTime()}`);
      
      // Update the parent component with the new project data
      onProjectUpdate({
        ...project,
        generated_code: response.data.code,
        infra_diagram_url: response.data.infra_diagram_url
      });
      
      CreditsFetch.fetchAvailableCredits();
    } catch (error) {
      setSnackbar({ open: true, message: "Error updating code", severity: "error" });
      if (error.response && error.response.status === 402) {
        // Handle low credits scenario
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSendPrompt = async () => {
    setLoading(true);
    const body = {
      project_id: id,
      new_prompt: newPrompt,
    };

    try {
      const response = await Apiservices.post("/api/generate/infra", body, {
        "Content-Type": "application/json",
      });

      setSnackbar({ open: true, message: "Prompt sent successfully!", severity: "success" });
      onProjectUpdate(response.data);
      setNewPrompt('');
      CreditsFetch.fetchAvailableCredits();
    } catch (error) {
      setSnackbar({ open: true, message: "Error sending prompt", severity: "error" });
      if (error.response && error.response.status === 402) {
        // Handle low credits scenario
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2} sx={{ backgroundColor: '#1E1E1E', color: 'white' }}>
      <Grid item xs={12} md={7}>
        <Paper elevation={3} style={{ padding: '20px', height: '100%', backgroundColor: '#2E2E2E' }}>
          {diagramUrl ? (
            <img
              src={diagramUrl}
              alt="Infrastructure Diagram"
              style={{ width: '100%', height: 'auto', maxHeight: 'calc(100vh - 200px)', objectFit: 'contain' }}
            />
          ) : (
            <Typography variant="h6" align="center" sx={{ color: 'white' }}>No diagram available</Typography>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper elevation={3} style={{ padding: '20px', height: '100%', backgroundColor: '#2E2E2E' }}>
          <Tabs 
            value={rightPaneTab} 
            onChange={(e, newValue) => setRightPaneTab(newValue)}
            sx={{
              '& .MuiTab-root': { color: 'white' },
              '& .Mui-selected': { color: '#68CC58' },
            }}
          >
            <Tab label="Prompts" />
            <Tab label="Code" />
          </Tabs>
          <Box sx={{ mt: 2, height: 'calc(100% - 48px)', display: 'flex', flexDirection: 'column' }}>
            {rightPaneTab === 0 ? (
              <>
                <Box sx={{ flexGrow: 1, height: 'calc(100% - 120px)', mb: 2, overflow: 'hidden' }}>
                  <Prompts 
                    prompt_history={project.prompt_history} 
                    sx={(theme) => ({
                      height: '100%', 
                      overflowY: 'auto',
                      color: 'white',
                      '& *': { color: 'white' },
                      // Spread the existing sx styles here
                      ...(typeof Prompts.sx === 'function' ? Prompts.sx(theme) : Prompts.sx),
                    })}
                  />
                </Box>
                <Box>
                  <TextField
                    label="New Prompt"
                    multiline
                    rows={3}
                    value={newPrompt}
                    onChange={handlePromptChange}
                    fullWidth
                    variant="outlined"
                    sx={{ 
                      mb: 2, 
                      '& .MuiInputBase-input': { color: 'white' },
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                      '& .MuiInputLabel-root': { color: 'white' },
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendPrompt}
                    disabled={loading || !newPrompt.trim()}
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </Box>
              </>
            ) : (
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <AceEditor
                  mode="python"
                  theme="monokai"
                  name="code-editor"
                  value={code}
                  onChange={handleCodeChange}
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    useWorker: false,
                    showPrintMargin: false,
                    highlightActiveLine: false,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                  }}
                  style={{ width: '100%', height: 'calc(100% - 50px)' }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCodeUpdate}
                  disabled={loading}
                  sx={{ mt: 2 }}
                >
                  {loading ? <CircularProgress size={24} /> : "Update Code"}
                </Button>
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default DiagramCodePrompt;