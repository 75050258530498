import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Snackbar,
  Alert,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Apiservices from "../../Utils/Apiservices";
import { checkIsAuthenticated } from "../../Utils/SessionData";

const Resources = ({ resources }) => {
  const isAuthenticated = checkIsAuthenticated();
console.log("resourcesresources",resources)
  const [resourceCounts, setResourceCounts] = useState(resources);
  const [bandwidthDescription, setBandwidthDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [cloudProvider, setCloudProvider] = useState('');
  const [region, setRegion] = useState('');

  const handleCountChange = (key, value) => {
    const newValue = Math.max(0, parseInt(value) || 0); // Ensure value is >= 0 and valid number
    setResourceCounts((prev) => ({
      ...prev,
      [key]: { ...prev[key], count: newValue },
    }));
  };

  const handleSubmit = async () => {
    if (!bandwidthDescription) {
      setSnackbar({
        open: true,
        message: "Bandwidth description is required.",
        severity: "error",
      });
      return;
    }

    const payload = {
      resources: resourceCounts,
      bandwidth_description: bandwidthDescription,
    };

    setLoading(true);

    try {
      const apiResponse = await Apiservices.post(
        "/api/generate/cost_estimate",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          }
        }
      );
      
      // Check if we have data in the response
      console.log("Raw API Response:", apiResponse);
      
      // Make sure we're accessing the correct property of the response
      const responseData = apiResponse.data ? apiResponse.data : apiResponse;
      console.log("Response Data:", responseData);

      if (!responseData || !responseData.breakdown) {
        throw new Error('Invalid response format');
      }

      setResponse(responseData);
      setSnackbar({
        open: true,
        message: "Cost estimate generated successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("API Error:", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to generate cost estimate.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severity: "" });
  };

  const handleCloudProviderChange = (event) => {
    setCloudProvider(event.target.value);
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  const cloudProviders = ['AWS', 'Azure', 'Google Cloud'];
  const regions = {
    'AWS': ['us-east-1', 'us-west-2', 'eu-west-1'],
    'Azure': ['eastus', 'westeurope', 'southeastasia'],
    'Google Cloud': ['us-central1', 'europe-west1', 'asia-east1']
  };

  return (
    <div>
      <Paper
        elevation={12}
        style={{ padding: "20px", backgroundColor: "#1E1E1E" }}
      >
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel id="cloud-provider-label" sx={{ color: 'white', backgroundColor: '#1E1E1E', padding: '0 5px' }}>Cloud Provider</InputLabel>
            <Select
              labelId="cloud-provider-label"
              value={cloudProvider}
              onChange={handleCloudProviderChange}
              label="Cloud Provider"
              sx={{
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                '& .MuiSvgIcon-root': { color: 'white' }
              }}
            >
              {cloudProviders.map((provider) => (
                <MenuItem key={provider} value={provider}>{provider}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel id="region-label" sx={{ color: 'white', backgroundColor: '#1E1E1E', padding: '0 5px' }}>Region</InputLabel>
            <Select
              labelId="region-label"
              value={region}
              onChange={handleRegionChange}
              label="Region"
              disabled={!cloudProvider}
              sx={{
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                '& .MuiSvgIcon-root': { color: 'white' }
              }}
            >
              {cloudProvider && regions[cloudProvider].map((region) => (
                <MenuItem key={region} value={region}>{region}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        
        <TableContainer component={Paper} sx={{ backgroundColor: '#2E2E2E' }}>
        {resources !== null &&
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    color: "white"
                  }}
                >
                  Resource
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "white" }} align="center">
                  Count
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources&& Object.entries(resourceCounts).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", color: "white" }}
                  >
                    {key}
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      value={value.count}
                      onChange={(e) => handleCountChange(key, e.target.value)}
                      inputProps={{ min: 0 }}
                      disabled={loading}
                      sx={{
                        '& .MuiInputBase-input': {
                          color: 'white', // Text color
                          backgroundColor: '#2E2E2E', // Background color
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}
        </TableContainer>
        <Typography variant="h6" style={{ marginTop: "20px", color: "white" }}>
          Bandwidth Description
        </Typography>
        <TextField
          fullWidth
          required
          variant="outlined"
          multiline
          rows={4}
          placeholder="Enter bandwidth description"
          value={bandwidthDescription}
          onChange={(e) => setBandwidthDescription(e.target.value)}
          style={{ marginBottom: "20px" }}
          disabled={loading}
          sx={{
            '& .MuiInputBase-input': {
              color: 'white', // Text color
              backgroundColor: '#2E2E2E', // Background color
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={loading && <CircularProgress size={24} />}
        >
          {loading ? "Creating Cost Estimate..." : "Create Cost Estimate"}
        </Button>
      </Paper>

      {console.log("Current response state:", response)}
      
      {response ? (
        <div style={{ marginTop: "20px", color: "white" }}>
          <Typography variant="h6" style={{ color: "white" }}>Cost Estimate Breakdown</Typography>
          <TableContainer component={Paper} style={{ marginTop: "10px", backgroundColor: '#2E2E2E' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>Service</TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }} align="right">
                    Cost ($)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(response.breakdown || {}).map(([service, cost]) => (
                  <TableRow key={service}>
                    <TableCell style={{ color: "white" }}>{service}</TableCell>
                    <TableCell align="right" style={{ color: "white" }}>
                      {typeof cost === 'number' ? cost.toFixed(2) : cost}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }} align="right">
                    {response.total_estimated_cost?.toFixed(2) || '0.00'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          
          <Accordion 
            sx={{ 
              marginTop: "20px",
              backgroundColor: '#2E2E2E',
              color: 'white',
              '&:before': {
                display: 'none', // Removes the default divider
              },
              '& .MuiAccordionSummary-root': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
              aria-controls="cost-explanation-content"
              id="cost-explanation-header"
            >
              <Typography variant="h6">Detailed Cost Explanation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography 
                style={{ 
                  whiteSpace: 'pre-line',
                  color: 'white',
                  padding: '16px 0'
                }}
              >
                {response.explanation}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : null}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Resources;
