import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import ApiService from '../../Utils/Apiservices';

const SyncSettings = ({ project }) => {
  const [githubConnected, setGithubConnected] = useState(false);
  const [repositories, setRepositories] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchGithubStatus();
    const storedRepo = localStorage.getItem(`selectedRepo_${project.id}`);
    if (storedRepo) {
      try {
        setSelectedRepo(JSON.parse(storedRepo));
      } catch (error) {
        console.error('Error parsing stored repository:', error);
        localStorage.removeItem(`selectedRepo_${project.id}`);
      }
    }
  }, [project.id]);

  const fetchGithubStatus = async () => {
    try {
      const response = await ApiService.get('/api/user/settings');
      setGithubConnected(response.settings.connected_apps.includes('github'));
      setLoading(false);
      if (response.settings.connected_apps.includes('github')) {
        fetchRepositories();
      }
    } catch (error) {
      console.error('Error fetching GitHub status:', error);
      setError('Failed to fetch GitHub connection status');
      setLoading(false);
    }
  };

  const fetchRepositories = async () => {
    try {
      const response = await ApiService.get('/api/user/github/repositories');
      setRepositories(response.repositories);
    } catch (error) {
      console.error('Error fetching repositories:', error);
      setError('Failed to fetch repositories');
    }
  };

  const handleGithubConnect = () => {
    const state = encodeURIComponent(JSON.stringify({ userId: project.user_id }));
    const APP_SLUG = 'infrajam';
    const githubInstallUrl = `https://github.com/apps/${APP_SLUG}/installations/new?state=${state}`;
    window.location.href = githubInstallUrl;
  };

  const handleGithubDisconnect = async () => {
    try {
      await ApiService.post('/api/user/disconnect-github');
      setGithubConnected(false);
      setRepositories([]);
      setSelectedRepo('');
      localStorage.removeItem(`selectedRepo_${project.id}`);
    } catch (error) {
      console.error('Error disconnecting GitHub:', error);
      setError('Failed to disconnect GitHub');
    }
  };

  const handleRepoChange = (event) => {
    const repo = event.target.value;
    setSelectedRepo(repo);
    localStorage.setItem(`selectedRepo_${project.id}`, JSON.stringify(repo));
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box sx={{ bgcolor: '#2E2E2E', color: 'white', p: 3, borderRadius: 2 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: '#2E2E2E', color: 'white', p: 3, borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom>
        GitHub Sync Settings
      </Typography>
      {githubConnected ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGithubDisconnect}
            sx={{ mb: 2 }}
          >
            Disconnect GitHub
          </Button>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="repo-select-label" sx={{ color: 'white' }}>Select Repository</InputLabel>
            <Select
              labelId="repo-select-label"
              value={selectedRepo}
              onChange={handleRepoChange}
              sx={{
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                '& .MuiSvgIcon-root': { color: 'white' }
              }}
            >
              {repositories.map((repo) => (
                <MenuItem key={repo.name} value={repo}>{repo.name} ({repo.private ? 'Private' : 'Public'})</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedRepo && (
            <Typography variant="body1">
              Selected Repository: {selectedRepo.name} ({selectedRepo.url})
            </Typography>
          )}
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleGithubConnect}
        >
          Connect GitHub
        </Button>
      )}
    </Box>
  );
};

export default SyncSettings;
