import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const ContactUs = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
          <Typography variant="body1" paragraph>
            Last updated on 13-09-2024 18:08:07
          </Typography>
          <Typography variant="body1" paragraph>
            You may contact us using the information below:
          </Typography>
          <Typography variant="body1" component="div">
            <Box component="ul" sx={{ pl: 2 }}>
              <li>Merchant Legal entity name: SHIVENDRA SONI</li>
              <li>Registered Address: 7/398, Vikas Nagar, Lucknow, Lucknow, Uttar Pradesh, PIN: 226022</li>
              <li>Operational Address: 7/398, Vikas Nagar, Lucknow, Lucknow, Uttar Pradesh, PIN: 226022</li>
              <li>Telephone No: 9741240200</li>
              <li>E-Mail ID: shivendrasoni91@gmail.com</li>
            </Box>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default ContactUs;