import React from 'react';

const Projects = () => {
  return (
    <div className="projects-container">
      <h1>Projects</h1>
      {/* Add your projects content here */}
    </div>
  );
};

export default Projects;
