import React, { useEffect, useState } from "react";
import Apiservices from "../../Utils/Apiservices";
import { checkIsAuthenticated } from '../../Utils/SessionData';
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Snackbar,
  Alert,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Box from '@mui/material/Box';
import { Grow, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format, isValid, parseISO } from 'date-fns';
import ProjectActionMenu from './ProjectActionMenu';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MyProjectsList = ({ onRowClick }) => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [resetSuccess, setResetSuccess] = useState("");
  const [resetError, setResetError] = useState("");
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [projectToRename, setProjectToRename] = useState(null);
  const isAuthenticated = checkIsAuthenticated();

  useEffect(() => {
    const storedProjects = localStorage.getItem("MyProjectsData");
    if (storedProjects) {
      const parsedProjects = JSON.parse(storedProjects);
      setProjects(parsedProjects);
      setFilteredProjects(parsedProjects);
      console.log("Loaded stored projects:", parsedProjects); // Debug log
    }
    fetchProjects();
  }, []);

  const fetchProjects = () => {
    console.log("Fetching projects..."); // Debug log
    Apiservices.get("/api/projects", {
      Authorization: `Bearer ${isAuthenticated}`,
    })
      .then((response) => {
        const fetchedProjects = response;
        console.log("Fetched projects:", response); // Debug log
        setProjects(fetchedProjects);
        setFilteredProjects(fetchedProjects);
        localStorage.setItem(
          "MyProjectsData",
          JSON.stringify(fetchedProjects)
        );
      })
      .catch((error) => {
        console.error("Error fetching projects:", error); // Debug log
        setError("Data not found. Please try again later.");
      });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setFilteredProjects(
      projects.filter((project) =>
        project.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };

  const handleRowClick = (project) => {
    onRowClick(project);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleResetClick = (project) => {
    setSelectedProject(project);
    setDialogOpen(true);
  };

  const handleConfirmReset = () => {
    setLoading(true);
    Apiservices.post(
      "/api/projects/reset",
      {
        project_id: selectedProject.id,
      },
      {
        Authorization: `Bearer ${isAuthenticated}`,
      }
    )
      .then(() => {
        setDialogOpen(false);
        setResetSuccess("Project has been reset successfully.");
        fetchProjects(); // Fetch updated data
      })
      .catch(() => {
        setDialogOpen(false);
        setResetError("Failed to reset the project. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateNew = () => {
    setIsCreateMode(true);
  };

  const handleCancelCreate = () => {
    setIsCreateMode(false);
    setNewProjectName("");
  };

  const handleCreateProject = () => {
    if (!newProjectName.trim()) {
      setError("Project name cannot be empty");
      return;
    }

    setLoading(true);
    
    Apiservices.post(
      "/api/projects",
      { project_name: newProjectName },
      {
        Authorization: `Bearer ${isAuthenticated}`
      }
    )
      .then((response) => {
        console.log("Project created successfully:", response.data);
        setResetSuccess("Project has been created successfully.");
        fetchProjects(); // Fetch updated data
        setIsCreateMode(false);
        setNewProjectName("");
      })
      .catch((error) => {
        console.error("Error creating project:", error);
        setError("Failed to create the project. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'MMM dd, yyyy HH:mm') : 'Invalid Date';
  };

  const handleMenuOpen = (event, project) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedProject(project);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedProject(null);
  };

  const handleReset = () => {
    handleMenuClose();
    handleResetClick(selectedProject);
  };

  const handleDelete = async () => {
    handleMenuClose();
    if (window.confirm("Are you sure you want to delete this project?")) {
      try {
        await Apiservices.delete(`/api/projects/${selectedProject.id}`, { Authorization: `Bearer ${isAuthenticated}` });
        setSnackbar({ open: true, message: "Project deleted successfully!", severity: "success" });
        fetchProjects();
      } catch (error) {
        setSnackbar({ open: true, message: "Error deleting project", severity: "error" });
      }
    }
  };

  const handleRename = () => {
    handleMenuClose();
    setProjectToRename(selectedProject);
    setNewProjectName(selectedProject.name);
    setRenameDialogOpen(true);
  };

  const handleRenameConfirm = async () => {
    if (!projectToRename) {
      setSnackbar({ open: true, message: "No project selected for renaming", severity: "error" });
      setRenameDialogOpen(false);
      return;
    }

    try {
      await Apiservices.put(`/api/projects/${projectToRename.id}/rename`, 
        { new_name: newProjectName },
        { Authorization: `Bearer ${isAuthenticated}` }
      );
      setSnackbar({ open: true, message: "Project renamed successfully!", severity: "success" });
      fetchProjects();
    } catch (error) {
      console.log("ERROR", error);
      setSnackbar({ open: true, message: "Error renaming project", severity: "error" });
    } finally {
      setRenameDialogOpen(false);
      setProjectToRename(null);
    }
  };

  const handleDuplicate = () => {
    handleMenuClose();
    // Implement duplicate functionality
    console.log("Duplicate project", selectedProject.id);
  };

  const sortProjects = (projects, sortConfig) => {
    if (!sortConfig.key) return projects;
    return [...projects].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
        <KeyboardArrowUpIcon 
          fontSize="small" 
          sx={{ 
            color: isActive && sortConfig.direction === 'ascending' ? 'primary.main' : 'grey',
            opacity: isActive ? 1 : 0.5,
          }} 
        />
        <KeyboardArrowDownIcon 
          fontSize="small" 
          sx={{ 
            color: isActive && sortConfig.direction === 'descending' ? 'primary.main' : 'grey',
            opacity: isActive ? 1 : 0.5,
            marginTop: '-8px',  // Adjust this value to bring the icons closer together
          }} 
        />
      </Box>
    );
  };

  // Update the useEffect hook to apply sorting
  useEffect(() => {
    const sortedProjects = sortProjects(filteredProjects, sortConfig);
    setFilteredProjects(sortedProjects);
  }, [sortConfig, projects]);

  return (
    <div style={{ backgroundColor: '#2e2e2e', color: 'white', padding: '20px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <TextField
          label="Search by Name"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ 
            flexGrow: 2,
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              color: 'white',
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255, 255, 255, 0.3)',
            },
          }}
        />
        <Box sx={{ flexGrow: 1, height: '3.5rem', position: 'relative' }}>
          <Grow in={!isCreateMode} unmountOnExit>
            <Button
              variant="contained"
              onClick={handleCreateNew}
              sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                backgroundColor: 'green',
                fontSize: '1.05rem',
                color: 'black',
                '&:hover': {
                  backgroundColor: 'darkgreen',
                },
              }}
            >
              Create
            </Button>
          </Grow>
          <Grow in={isCreateMode} unmountOnExit>
            <TextField
              label="Project Name"
              variant="outlined"
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
              sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  color: 'white',
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton
                      edge="end"
                      onClick={handleCreateProject}
                      sx={{ 
                        p: '10px',
                        color: 'white',
                        backgroundColor: 'success.main',
                        '&:hover': {
                          backgroundColor: 'success.dark',
                        },
                        mr: 1,
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={handleCancelCreate}
                      sx={{ 
                        p: '10px',
                        color: 'white',
                        backgroundColor: 'error.main',
                        '&:hover': {
                          backgroundColor: 'error.dark',
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleCreateProject();
                }
              }}
            />
          </Grow>
        </Box>
      </Box>

      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError("")}
        >
          <Alert onClose={() => setError("")} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
      {resetSuccess && (
        <Snackbar
          open={!!resetSuccess}
          autoHideDuration={6000}
          onClose={() => setResetSuccess("")}
        >
          <Alert onClose={() => setResetSuccess("")} severity="success">
            {resetSuccess}
          </Alert>
        </Snackbar>
      )}
      {resetError && (
        <Snackbar
          open={!!resetError}
          autoHideDuration={6000}
          onClose={() => setResetError("")}
        >
          <Alert onClose={() => setResetError("")} severity="error">
            {resetError}
          </Alert>
        </Snackbar>
      )}
      {loading && (
        <CircularProgress style={{ display: "block", margin: "20px auto" }} />
      )}
      {!loading && filteredProjects && filteredProjects.length > 0 && (
        <TableContainer component={Paper} sx={{ backgroundColor: '#2e2e2e', color: 'white' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {['ID', 'Name', 'Created At', 'Updated At', 'Actions'].map((header, index) => (
                  <TableCell 
                    key={index}
                    sx={{ 
                      fontWeight: "bold", 
                      padding: "8px", 
                      color: 'white',
                      borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                    }}
                  >
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        cursor: 'pointer' 
                      }} 
                      onClick={() => requestSort(header.toLowerCase().replace(' ', '_'))}
                    >
                      {header} {getSortIcon(header.toLowerCase().replace(' ', '_'))}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProjects
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((project) => (
                  <TableRow
                    key={project.id}
                    onClick={() => {
                      console.log("Row clicked:", project); // Debug log
                      handleRowClick(project);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {['id', 'name', 'created_at', 'updated_at'].map((field, index) => (
                      <TableCell 
                        key={index}
                        sx={{ 
                          padding: "8px", 
                          color: 'white',
                          borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                        }}
                      >
                        {field.includes('_at') ? formatDate(project[field]) : project[field]}
                      </TableCell>
                    ))}
                    <TableCell 
                      sx={{ 
                        padding: "8px",
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={(e) => handleMenuOpen(e, project)}
                        title="Project Actions"
                        sx={{ color: 'white' }}
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredProjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ 
              color: 'white',
              '& .MuiSvgIcon-root': {
                color: 'white',
              },
            }}
          />
        </TableContainer>
      )}
      {!loading && filteredProjects.length === 0 && !error && (
        <Typography variant="h6" align="center" color="white">
          No projects available.
        </Typography>
      )}
      <Dialog 
        open={dialogOpen} 
        onClose={() => setDialogOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: '#2e2e2e',
            color: 'white',
          },
        }}
      >
        <DialogTitle>Confirm Reset</DialogTitle>
        <DialogContent>
          <Typography color="white">
            Do you want to reset the project with ID{" "}
            <strong>{selectedProject?.id}</strong> and Name{" "}
            <strong>{selectedProject?.name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <Button onClick={handleConfirmReset} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ProjectActionMenu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        onReset={handleReset}
        onDelete={handleDelete}
        onRename={handleRename}
        onDuplicate={handleDuplicate}
      />
      <Dialog open={renameDialogOpen} onClose={() => {
        setRenameDialogOpen(false);
        setProjectToRename(null);
      }}>
        <DialogTitle>Rename Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Project Name"
            type="text"
            fullWidth
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setRenameDialogOpen(false);
            setProjectToRename(null);
          }}>Cancel</Button>
          <Button onClick={handleRenameConfirm}>Rename</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MyProjectsList;