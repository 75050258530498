import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import CryptoJS from 'crypto-js';
import Apiservices from '../Utils/Apiservices';

const OAuthHandler = ({ secretKey }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleOAuthCallback = async () => {
            try {
                const { data, error } = await supabase.auth.getSession();

                if (error) {
                    console.error('Error getting session:', error);
                    navigate('/');
                    return;
                }

                if (data.session) {
                    const { user, access_token, refresh_token } = data.session;

                    console.log('Session data:', { access_token, refresh_token, user });

                    const adaptedUserData = {
                        token: access_token,
                        refresh_token: refresh_token,
                        expires_at: data.session.expires_at,
                        user: {
                            email: user.email,
                            full_name: user.user_metadata.full_name || user.email,
                        }
                    };

                    console.log('Adapted user data:', adaptedUserData);

                    let encryptedAccessToken, encryptedRefreshToken, encryptedUserDetails;

                    try {
                        if (!secretKey) {
                            throw new Error('Encryption key is not available');
                        }
                        encryptedAccessToken = CryptoJS.AES.encrypt(adaptedUserData.token, secretKey).toString();
                        encryptedRefreshToken = CryptoJS.AES.encrypt(adaptedUserData.refresh_token, secretKey).toString();
                        encryptedUserDetails = CryptoJS.AES.encrypt(JSON.stringify(adaptedUserData.user), secretKey).toString();
                    } catch (encryptionError) {
                        console.error('Error during encryption:', encryptionError);
                        console.log('Falling back to base64 encoding');
                        encryptedAccessToken = btoa(adaptedUserData.token);
                        encryptedRefreshToken = btoa(adaptedUserData.refresh_token);
                        encryptedUserDetails = btoa(JSON.stringify(adaptedUserData.user));
                    }

                    sessionStorage.setItem("LoginUserAccessToken", encryptedAccessToken);
                    sessionStorage.setItem("LoginUserRefreshToken", encryptedRefreshToken);
                    sessionStorage.setItem("LoginUserDetails", encryptedUserDetails);
                    sessionStorage.setItem("Expiry", adaptedUserData.expires_at);

                    console.log('Data stored in session storage');

                    // Check if it's a new user
                    try {
                        const response = await Apiservices.get('/api/user/check-new-user', {
                            headers: { 'Authorization': `Bearer ${access_token}` }
                        });
                        if (response.data.isNewUser) {
                            navigate('/additional-info');
                        } else {
                            navigate('/client/projects');
                        }
                    } catch (error) {
                        console.error('Error checking new user:', error);
                        navigate('/client/projects');
                    }
                } else {
                    console.log('No session data available');
                    navigate('/');
                }
            } catch (error) {
                console.error('Unexpected error in OAuth handler:', error);
                navigate('/');
            }
        };

        handleOAuthCallback();
    }, [navigate, secretKey]);

    return <div>Completing login...</div>;
};

export default OAuthHandler;