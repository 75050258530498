import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Settings.css';
import { getDecryptedUserData } from "../../Utils/SessionData";
import ApiService from '../../Utils/Apiservices';
import { Snackbar } from '@mui/material';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('team');
  const [notionConnected, setNotionConnected] = useState(false);
  const [githubConnected, setGithubConnected] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);
  const [userId, setUserId] = useState(null);
  const [githubToken, setGithubToken] = useState(null);
  const [settings, setSettings] = useState(null);
  const [customComponents, setCustomComponents] = useState([]);
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = getDecryptedUserData();
      if (userData && userData.id) {
        setUserId(userData.id);
        await fetchUserSettings();
      } else {
        console.error('User ID not found in decrypted user data');
      }
    };

    fetchUserData();
  }, []);

  const getUserSettings = async () => {
    try {
      
      const response = await ApiService.get('/api/user/settings');
      
      // Return hardcoded response
      return response;
    } catch (error) {
      console.error('Error fetching user settings:', error);
      throw error;
    }
  };

  const fetchUserSettings = async () => {
    try {
      const settingsData = await getUserSettings();
      setSettings(settingsData);
      updateSettingsState(settingsData.settings);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const updateSettingsState = (settingsData) => {
    const { connected_apps, api_key, custom_components } = settingsData;
    setNotionConnected(connected_apps.includes('notion'));
    setGithubConnected(connected_apps.includes('github'));
    setApiKey(api_key);
    setCustomComponents(custom_components);
  };

  useEffect(() => {
    const state = location.state;
    if (state && state.github_token) {
      setGithubToken(state.github_token);
      setGithubConnected(true);
    }
  }, [location]);

  const handleNotionToggle = () => {
    setSnackbarMessage("Connecting Notion is currently in alpha, we are gradually enabling it for everyone.");
    setSnackbarOpen(true);
  };

  const handleGithubDisconnect = () => {
    // Open GitHub app settings page in a new window
    window.open('https://github.com/settings/installations', '_blank');
  };

  const handleGithubToggle = () => {
    if (githubConnected) {
      handleGithubDisconnect();
    } else {
      handleGithubConnect();
    }
  };

  const handleGithubConnect = () => {
    const state = encodeURIComponent(JSON.stringify({ userId: userId }));
    const APP_SLUG = 'infrajam';
    const githubInstallUrl = `https://github.com/apps/${APP_SLUG}/installations/new?state=${state}`;
    window.location.href = githubInstallUrl;
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const ApiAccessContent = () => (
    <div className="api-access-content">
      <h2>API Key</h2>
      <p>Use this key to access the API. Keep it secret and secure.</p>
      <div className="api-key-container">
        <input
          type={showApiKey ? 'text' : 'password'}
          value={apiKey}
          readOnly
          className="api-key-input"
        />
        <button onClick={toggleApiKeyVisibility} className="toggle-visibility-btn">
          {showApiKey ? 'Hide' : 'Show'}
        </button>
      </div>
    </div>
  );

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'team':
        return <div>Team settings content</div>;
      case 'customComponents':
        return (
          <div>
            <h2>Custom Components</h2>
            <ul>
              {customComponents.map((component, index) => (
                <li key={index}>
                  <img src={component.icon_url} alt={component.name} width="24" height="24" />
                  <span>{component.name}</span>
                  <span>({component.category})</span>
                </li>
              ))}
            </ul>
          </div>
        );
      case 'connections':
        return (
          <div className="connections-content">
            <p>Connect your Notion and GitHub accounts to enable full functionality.</p>
            <button 
              className={`connect-button notion ${notionConnected ? 'disconnect' : ''}`}
              onClick={handleNotionToggle}
            >
              {notionConnected ? 'Disconnect Notion' : 'Connect Notion'}
            </button>
            
            <button 
              className={`connect-button github ${githubConnected ? 'disconnect' : ''}`}
              onClick={handleGithubToggle}
            >
              {githubConnected ? 'Disconnect GitHub' : 'Connect GitHub'}
            </button>
          </div>
        );
      case 'apiAccess':
        return <ApiAccessContent />;
      default:
        return null;
    }
  };

  return (
    <div className="settings-container">
      <h1>Settings</h1>
      
      <div className="settings-layout">
        <div className="settings-tabs">
          <button 
            className={`tab-button ${activeTab === 'team' ? 'active' : ''}`}
            onClick={() => setActiveTab('team')}
          >
            Team
          </button>
          <button 
            className={`tab-button ${activeTab === 'customComponents' ? 'active' : ''}`}
            onClick={() => setActiveTab('customComponents')}
          >
            Custom Components
          </button>
          <button 
            className={`tab-button ${activeTab === 'connections' ? 'active' : ''}`}
            onClick={() => setActiveTab('connections')}
          >
            Connections
          </button>
          <button 
            className={`tab-button ${activeTab === 'apiAccess' ? 'active' : ''}`}
            onClick={() => setActiveTab('apiAccess')}
          >
            API Access
          </button>
        </div>

        <div className="settings-content">
          {renderTabContent()}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Settings;
