import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    At InfraRad, we are committed to protecting your privacy and ensuring the security of your data. This privacy policy outlines how we collect, use, and safeguard your information.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Ownership of Content
                </Typography>
                <Typography variant="body1" paragraph>
                    All diagrams, documents, projects, and images created or uploaded by users belong exclusively to the respective users. We will never use this content for training our systems or any other purposes without explicit consent.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Data Usage
                </Typography>
                <Typography variant="body1" paragraph>
                    We may collect anonymized metrics to improve our product and services. This data is aggregated and does not contain any personally identifiable information.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Data Protection
                </Typography>
                <Typography variant="body1" paragraph>
                    We implement robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.
                </Typography>
                <Typography variant="body1" paragraph>
                    For any questions or concerns about our privacy policy, please contact us.
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;