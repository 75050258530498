import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Assests/default.svg";
import Apiservices from "../../Utils/Apiservices";
import CustomButton from '../CustomButton';
import { Analytics } from '@vercel/analytics/react';

const SignUpPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", type: "success" });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (field, value) => {
    let error = "";
    switch (field) {
      case "email":
        error = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "Invalid email address" : "";
        break;
      case "password":
        error = !/^(?=.*\d).{8,}$/.test(value) ? "Password must be at least 8 characters and include a number" : "";
        if (!error && formData.confirmPassword && value !== formData.confirmPassword) {
          setErrors(prev => ({ ...prev, confirmPassword: "Passwords do not match" }));
        } else if (!error && formData.confirmPassword) {
          setErrors(prev => ({ ...prev, confirmPassword: "" }));
        }
        break;
      case "confirmPassword":
        error = value !== formData.password ? "Passwords do not match" : "";
        break;
      default:
        break;
    }
    setErrors(prev => ({ ...prev, [field]: error }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Validate all fields
    Object.entries(formData).forEach(([field, value]) => validateField(field, value));

    // Check for errors and empty required fields
    const hasErrors = Object.values(errors).some(error => error);
    const hasEmptyFields = Object.values(formData).some(value => !value);

    if (hasErrors || hasEmptyFields) {
      setSnackbar({ open: true, message: "Please fill all fields correctly", type: "error" });
      return;
    }

    setLoading(true);
    try {
      const response = await Apiservices.post("/api/auth/signup", {
        email: formData.email,
        password: formData.password,
      });

      setSnackbar({
        open: true,
        message: "Signup Successful! Please check your inbox and verify your email.",
        type: "success"
      });
      setTimeout(() => navigate("/"), 6000); // Increased delay to 6 seconds
    } catch (error) {
      console.error("Signup error:", error);
      let errorMessage = "Failed to signup, please try again later.";
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = error.response.data.detail;
      }
      setSnackbar({ open: true, message: errorMessage, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        background: "linear-gradient(0deg, #FFFFFF 30%, #131313 99%)",
      }}>
        <Container>
          <Grid container spacing={5} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Box sx={{ textAlign: "center", mb: 4 }}>
                <img src={Logo} alt="logo" style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{
                bgcolor: "background.default",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                p: 4,
              }}>
                <Avatar sx={{ bgcolor: "#2689d5", mb: 2, width: 80, height: 80, mx: "auto" }}>
                  <AccountCircleIcon sx={{ width: 60, height: 60 }} />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ mb: 3, textAlign: "center" }}>
                  Create New Account
                </Typography>
                <form onSubmit={handleSignUp}>
                  {["email", "password", "confirmPassword"].map((field) => (
                    <TextField
                      key={field}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id={field}
                      label={field === "confirmPassword" ? "Confirm Password" : field.charAt(0).toUpperCase() + field.slice(1)}
                      name={field}
                      autoComplete={field === "email" ? "email" : "new-password"}
                      type={field.includes("password") ? (field === "password" ? (showPassword ? "text" : "password") : (showConfirmPassword ? "text" : "password")) : "text"}
                      value={formData[field]}
                      onChange={handleInputChange}
                      error={!!errors[field]}
                      helperText={errors[field]}
                      InputProps={field.includes("password") ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => field === "password" ? setShowPassword(!showPassword) : setShowConfirmPassword(!showConfirmPassword)}>
                              {field === "password" ? (showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />) : (showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />)}
                            </IconButton>
                          </InputAdornment>
                        ),
                      } : undefined}
                    />
                  ))}
                  <CustomButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, bgcolor: "#2689d5", "&:hover": { bgcolor: "#2689d5" } }}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : "Sign Up"}
                  </CustomButton>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link to="/" variant="body2">
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Analytics />
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.type === "success" ? 6000 : 4000} // Longer duration for success message
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.type}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default SignUpPage;
