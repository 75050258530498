import React, { useState, useEffect } from 'react';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Paper, 
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert
} from '@mui/material';
import ApiService from '../../Utils/Apiservices';

const RepositorySelection = ({ project, onProjectUpdate }) => {
  const [repositories, setRepositories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [inProgressAutomations, setInProgressAutomations] = useState(() => {
    const saved = localStorage.getItem('inProgressAutomations');
    return saved ? JSON.parse(saved) : [];
  });

  // Hardcoded automation options
  const automationOptions = [
    {
      id: 'swagger',
      label: 'Generate Swagger API Docs',
      description: 'Api services only',
      disabled: false
    },
    {
      id: 'storybook',
      label: 'Generate Story Book',
      description: 'Only React repos supported',
      disabled: false
    },
    {
      id: 'docstrings',
      label: 'Add Inline Docstrings',
      description: '',
      disabled: false
    },
    {
      id: 'sequence',
      label: 'Generate Sequence Diagrams',
      description: 'Coming Soon',
      disabled: true
    }
  ].map(option => ({
    ...option,
    disabled: option.disabled || inProgressAutomations.includes(option.id),
    label: `${option.label}${inProgressAutomations.includes(option.id) ? ' (In Progress)' : ''}`
  }));

  useEffect(() => {
    const fetchRepositories = async () => {
      try {
        const response = await ApiService.get('/api/user/github/repositories');
        setRepositories(response.repositories);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching repositories:', err);
        setError('Failed to fetch repositories. Please try again later.');
        setLoading(false);
      }
    };

    fetchRepositories();
  }, []);

  const handleRepositorySelect = (repo) => {
    setSelectedRepo(repo);
  };

  const handleAutomationSelect = (event) => {
    const selectedAutomation = event.target.value;
    
    // Add to in-progress automations
    const updatedAutomations = [...inProgressAutomations, selectedAutomation];
    setInProgressAutomations(updatedAutomations);
    localStorage.setItem('inProgressAutomations', JSON.stringify(updatedAutomations));
    
    // Show snackbar after 500ms delay
    setTimeout(() => {
      setShowSnackbar(true);
    }, 500);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setShowSnackbar(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: '100%', mb: 4 }}>
        <Paper style={{ padding: '16px', marginBottom: '16px' }}>
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', mb: 4 }}>
      <Paper style={{ padding: '16px', marginBottom: '16px' }}>
        <Typography variant="h6" gutterBottom>
          Select a Repository
        </Typography>
        {repositories.length === 0 ? (
          <Typography variant="body1">No repositories found.</Typography>
        ) : (
          <List>
            {repositories.map((repo) => (
              <ListItem
                button
                key={repo.name}
                onClick={() => handleRepositorySelect(repo)}
                selected={selectedRepo?.name === repo.name}
              >
                <ListItemText 
                  primary={repo.name} 
                  secondary={repo.private ? 'Private' : 'Public'}
                />
              </ListItem>
            ))}
          </List>
        )}

        {selectedRepo && (
          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth>
              <InputLabel>Select Automation</InputLabel>
              <Select
                label="Select Automation"
                onChange={handleAutomationSelect}
                defaultValue=""
              >
                {automationOptions.map((option) => (
                  <MenuItem 
                    key={option.id} 
                    value={option.id}
                    disabled={option.disabled}
                  >
                    <Box>
                      <Typography variant="body1">
                        {option.label}
                      </Typography>
                      {option.description && (
                        <Typography variant="caption" color="textSecondary">
                          ({option.description})
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Paper>

      <Snackbar 
        open={showSnackbar} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Automation job started! A PR will be created shortly.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RepositorySelection;
