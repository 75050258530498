import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../../Assests/isolated-monochrome-white.svg";
import { ExitToApp, Settings } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Link as MUILink,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Apiservices from "../../Utils/Apiservices";
import {
  getDecryptedUserData,
} from "../../Utils/SessionData";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import Chip from "@mui/material/Chip";
import { checkIsAuthenticated } from '../../Utils/SessionData';
import CreditsFetch from "../../Utils/CreditsFetch/CreditsFetch";
import BuildIcon from '@mui/icons-material/Build'; // Import icon for Tools

function InfraRadNavBar({ toggleDrawer }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [lowCreditsDialogOpen, setLowCreditsDialogOpen] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width:1440px)");
  const isAuthenticated = checkIsAuthenticated();
  const LoginUserData = getDecryptedUserData();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const handleLogoutClick = () => {
    setLogoutDialogOpen(true);
    handleClose();
  };

  const handleLogoutConfirm = () => {
    setIsLoggingOut(true);
    
    // Make an API call to logout
    Apiservices.post('/api/auth/logout')
      .then(() => {
        // If logout is successful, clear client-side state and redirect
        performClientSideLogout();
      })
      .catch((error) => {
        console.error('Logout API error:', error);
        // Even if there's an error, we should still logout on the client side
        performClientSideLogout();
      });
  };

  const performClientSideLogout = () => {
    // Clear any client-side state that doesn't rely on cookies
    // For example, if you're using any local storage items:
    localStorage.clear();
    
    // If you're using any global state management (like Redux or Context),
    // reset the state here
    // For example: dispatch(resetAuthState());

    setIsLoggingOut(false);
    setLogoutDialogOpen(false);

    // Redirect to the login page
    navigate("/login");
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  const handleSettingsClick = () => {
    navigate("/client/settings");
  };

  const [myCredits, setMyCredits] = useState(null);

  useEffect(() => {
    // Fetch credits when component mounts
    const fetchCredits = async () => {
      try {
        await CreditsFetch.fetchAvailableCredits();
        const storedCredits = localStorage.getItem("MyCredits");
        setMyCredits(storedCredits ? parseInt(storedCredits, 10) : 0);
      } catch (error) {
        console.error("Error fetching credits:", error);
        setMyCredits(0);
      }
    };

    fetchCredits();

    // Set up an interval to refresh credits every 5 minutes
    const intervalId = setInterval(fetchCredits, 5 * 60 * 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (myCredits < 5) {
      setLowCreditsDialogOpen(true);
    }
  }, [myCredits]);

  const handleCreditsClick = () => {
    if (myCredits < 5) {
      setLowCreditsDialogOpen(true);
    }
  };

  const handleRechargeClick = () => {
    setLowCreditsDialogOpen(false);
    window.open(`${process.env.REACT_APP_REACHARGE_LINK}`, "_blank");
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#121212",
          color: "white",
          borderBottom: "1px solid #2a2a2a",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                src={logo}
                alt="InfraRad Logo"
                style={{ width: 100, height: 40 }}
                onError={(e) => {
                  console.error("Error loading logo:", e);
                  e.target.src = "https://via.placeholder.com/100x40?text=Logo+Not+Found";
                }}
              />
            </Link>
            <Chip
              label="BETA"
              size="small"
              sx={{
                height: '16px',
                fontSize: '0.6rem',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                color: 'rgba(255, 255, 255, 0.7)',
                marginLeft: '8px',
                '& .MuiChip-label': {
                  padding: '0 6px',
                },
              }}
            />
          </Box>
          
          {/* Navigation links */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
            <Link to="/client/projects" style={{ textDecoration: "none", color: "inherit", marginRight: 16 }}>
              <Button
                startIcon={<DashboardIcon />}
                sx={{
                  color: location.pathname === "/client/projects" ? "#68CC58" : "white",
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
                }}
              >
                Dashboard
              </Button>
            </Link>

            <Link to="/projects" style={{ textDecoration: "none", color: "inherit", marginRight: 16 }}>
              <Button
                startIcon={<DashboardIcon />}
                sx={{
                  color: location.pathname === "/projects" ? "#68CC58" : "white",
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
                }}
              >
                Projects
              </Button>
            </Link>

            <Link to="/tools" style={{ textDecoration: "none", color: "inherit", marginRight: 16 }}>
              <Button
                startIcon={<BuildIcon />}
                sx={{
                  color: location.pathname === "/tools" ? "#68CC58" : "white",
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
                }}
              >
                Tools
              </Button>
            </Link>
          </Box>

          {/* Credits and user menu */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isLargeScreen && (
              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", marginRight: 2, cursor: "pointer" }}
                onClick={handleCreditsClick}
              >
                <MonetizationOnIcon sx={{ fill: "goldenrod", marginRight: 0.5 }} />
                Credits:{" "}
                <span
                  style={{
                    color: myCredits && myCredits < 5 ? "red" : "#68CC58",
                    marginLeft: 4,
                  }}
                >
                  {myCredits === null ? 0 : myCredits}
                </span>
              </Typography>
            )}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="user account"
              onClick={handleMenu}
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* User menu */}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link
            to="/client/profile"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {LoginUserData?.full_name.toUpperCase()}
          </Link>
        </MenuItem>
        {!isLargeScreen && (
          <MenuItem onClick={handleClose}>
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center" }}
              onClick={handleCreditsClick}
            >
              <MonetizationOnIcon sx={{ fill: "goldenrod", marginRight: 1 }} /> Credits:{" "}
              <span style={{ color: "#68CC58", marginLeft: 1 }}>
                {myCredits === null ? 0 : myCredits}
              </span>
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleSettingsClick}>
          <Button startIcon={<Settings />} style={{ color: "grey" }}>
            Settings
          </Button>
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <Button startIcon={<ExitToApp />} style={{ color: "red" }}>
            Logout
          </Button>
        </MenuItem>
      </Menu>

      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutCancel}
        aria-labelledby="logout-dialog-title"
      >
        <DialogTitle id="logout-dialog-title">Logout Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogoutCancel}
            sx={{ color: theme.palette.secondary.main }}
          >
            No
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            sx={{ color: theme.palette.primary.main }}
          >
            {isLoggingOut ? <CircularProgress size={24} /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={lowCreditsDialogOpen}
        onClose={() => setLowCreditsDialogOpen(false)}
        aria-labelledby="low-credits-dialog-title"
      >
        <DialogTitle id="low-credits-dialog-title">Low Credits</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your credits are running low. Please recharge to continue using our
            services.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRechargeClick}
            sx={{ color: theme.palette.primary.main }}
          >
            Click here to Recharge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InfraRadNavBar;
